import {Component} from '@angular/core';
import { OtaButtonModule } from '@cvpp-ota/input';

@Component({
    selector: 'app-bulkmail-subscription',
    templateUrl: './bulkmail-subscription.component.html',
    styleUrls: ['./bulkmail-subscription.component.scss'],
    standalone: true,
    imports: [OtaButtonModule]
})
export class BulkmailSubscriptionComponent {

    subscribeClick() {
    }
}
