import { enableProdMode, importProvidersFrom } from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';


import {environment} from './environments/environment';
import { AppComponent } from './app/app.component';
import { OtaSecurityModule, OtaSecurityConfigModule } from '@cvpp-ota/security';
import { OtaNavigationModule, OtaFooterModule, OtaMenuModule } from '@cvpp-ota/menu';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, OtaNavigationModule, OtaFooterModule, OtaMenuModule.forRoot({
            apsRules: environment.apsRules,
            baseUrls: environment.baseUrls,
            homePageUrl: environment.homePageUrl,
            profileMenuOptions: environment.profileMenuOptions
        }), OtaSecurityModule, OtaSecurityConfigModule.forRoot({
            fordUserAuthUrl: environment.fordUserAuthUrl,
            applicationIds: environment.applicationIds,
            apsRules: environment.apsRules,
            protocol: environment.protocol,
            authRequest: {
                oauthGrantType: environment.oauthGrantType,
                oauthClientId: environment.apsOAuthClientId,
                oauthClientSecret: environment.apsOAuthClientSecret,
                oauthScope: environment.apsOAuthScope,
                oauthProviderUrl: environment.apsOAuthProviderUrl,
                oauthResource: environment.oauthResource
            },
            login: {
                ssoServiceUrl: environment.oauth.ssoServiceUrl,
                ssoClientId: environment.oauth.ssoClientId,
                ssoResourceId: environment.oauth.ssoResourceId,
                ssoRedirectUri: environment.oauth.ssoRedirectUri,
                ssoResponseType: environment.oauth.ssoResponseType,
                ssoEnabled: true
            },
            apsPepUrl: environment.apsPepUrl,
            idTokenKey: environment.idTokenKey,
            tokenExpKey: environment.tokenExpKey,
            apsRulesKey: environment.apsRulesKey
        })),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
  .catch(err => console.error(err));
