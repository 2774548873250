import {Component, OnInit} from '@angular/core';
import {OtaApsService, OtaUserDataService} from '@cvpp-ota/security';
import { OtaMenuApsV1Access, OtaMenuUserData, OtaNavigationModule, OtaFooterModule } from '@cvpp-ota/menu';
import {environment} from '../environments/environment';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [OtaNavigationModule, RouterOutlet, OtaFooterModule]
})
export class AppComponent implements OnInit {
  title = 'CVPP-OTA-UI-Home';
  apsAccessList: OtaMenuApsV1Access;

  constructor(private userDataService: OtaUserDataService,
              private apsService: OtaApsService) {
  }

  ngOnInit() {
    if (sessionStorage.getItem(environment.apsRulesKey)) {
      this.apsAccessList = this.apsService.getApsAccess() as OtaMenuApsV1Access;
    }
    this.apsService.getApsResponseReceived().subscribe((apsV1Access) => {
      this.apsAccessList = apsV1Access as OtaMenuApsV1Access;
    });
    this.apsService.getApsResponseRefreshed().subscribe((apsMessage) => {
      console.log('apsMessage', apsMessage);
      if (apsMessage === 'clear') {
        this.apsAccessList = undefined;
      }
    })
  }

  getUser(): OtaMenuUserData {
    return this.userDataService.getUser() as OtaMenuUserData;
  }
}
