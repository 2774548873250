import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-image-header',
  templateUrl: './image-header.component.html',
  styleUrls: ['./image-header.component.scss'],
  imports: [NgOptimizedImage],
  standalone: true
})
export class ImageHeaderComponent {

  userGuideClick() {

  }

  faqClick() {

  }
}
