import {Component} from '@angular/core';
import { BulkmailSubscriptionComponent } from './bulkmail-subscription/bulkmail-subscription.component';
import { CardCarouselComponent } from './card-carousel/card-carousel.component';
import { ImageHeaderComponent } from './image-header/image-header.component';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    standalone: true,
    imports: [ImageHeaderComponent, CardCarouselComponent, BulkmailSubscriptionComponent]
})
export class HomePageComponent {

}
